<template>
    <div class="HomeHistory">
        <div class="main">
            <div class="title">发展历程</div>

            <div class="Companyhistory">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-tabs v-model="time" @tab-click="handleClick">
                            <el-tab-pane label="2021年" name="2021">
                                <el-carousel
                                    type="card"
                                    @change="carouselChange"
                                    :autoplay="false"
                                >
                                    <el-carousel-item
                                        v-for="(item, index) in dataEvent21"
                                        :key="item + index"
                                    >
                                        <img
                                            :src="item.imagUrl"
                                            width="100%"
                                            height="85%"
                                        />
                                        <div
                                            class="carousel-text"
                                            v-if="activeIndex == index"
                                        >
                                            <p class="carousel-title">
                                                {{ item.title }}
                                            </p>
                                            <span style="color: #000">
                                                {{ item.eventContent }}
                                            </span>
                                        </div>
                                    </el-carousel-item>
                                </el-carousel>
                            </el-tab-pane>
                            <el-tab-pane label="2022年" name="2022">
                                <el-carousel
                                    type="card"
                                    @change="carouselChange"
                                    :autoplay="false"
                                >
                                    <el-carousel-item
                                        v-for="(item, index) in dataEvent22"
                                        :key="item + index"
                                    >
                                        <img
                                            :src="item.imagUrl"
                                            width="100%"
                                            height="85%"
                                        />
                                        <div
                                            class="carousel-text"
                                            v-if="activeIndex == index"
                                        >
                                            <p class="carousel-title">
                                                {{ item.title }}
                                            </p>
                                            <span style="color: #000">
                                                {{ item.eventContent }}
                                            </span>
                                        </div>
                                    </el-carousel-item>
                                </el-carousel>
                            </el-tab-pane>
                            <el-tab-pane label="2023年" name="2023">
                                <el-carousel
                                    type="card"
                                    @change="carouselChange"
                                    :autoplay="false"
                                >
                                    <el-carousel-item
                                        v-for="(item, index) in dataEvent23"
                                        :key="item + index"
                                    >
                                        <img
                                            :src="item.imagUrl"
                                            width="100%"
                                            height="85%"
                                        />
                                        <div
                                            class="carousel-text"
                                            v-if="activeIndex == index"
                                        >
                                            <p class="carousel-title">
                                                {{ item.title }}
                                            </p>
                                            <span style="color: #000">
                                                {{ item.eventContent }}
                                            </span>
                                        </div>
                                    </el-carousel-item>
                                </el-carousel>
                            </el-tab-pane>
                            <el-tab-pane label="2024年" name="2024">
                                <el-carousel
                                    type="card"
                                    @change="carouselChange"
                                    :autoplay="false"
                                >
                                    <el-carousel-item
                                        v-for="(item, index) in dataEvent24"
                                        :key="item + index"
                                    >
                                        <img
                                            :src="item.imagUrl"
                                            width="100%"
                                            height="85%"
                                        />
                                        <div
                                            class="carousel-text"
                                            v-if="activeIndex == index"
                                        >
                                            <p class="carousel-title">
                                                {{ item.title }}
                                            </p>
                                            <span style="color: #000">
                                                {{ item.eventContent }}
                                            </span>
                                        </div>
                                    </el-carousel-item>
                                </el-carousel>
                            </el-tab-pane>
                        </el-tabs>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'History',
    props: {},
    created() {},
    methods: {
        handleClick(tab) {
            this.time = tab.name;
            this.activeIndex = 0;
        },
        carouselChange(index) {
            this.activeIndex = index;
        }
    },
    data() {
        return {
            time: '2023',
            activeIndex: 0,
            dataEvent21: [
                {
                    title: '2021年3月',
                    eventContent: '世泊公司广州市首座智能立体停车库正式开工建设',
                    imagUrl: require('@/assets/cover/history/2021/kaigong.jpg')
                }
            ],
            dataEvent22: [
                {
                    title: '2022年6月',
                    eventContent: '世泊公司组织全体员工“大夫山团建考察活动”',
                    imagUrl: require('@/assets/cover/history/2022/dafusan.jpg')
                },
                {
                    title: '2022年7月',
                    eventContent: '世泊公司团队研发组装第二代AMR机器人',
                    imagUrl: require('@/assets/cover/history/2022/two.jpg')
                },
                {
                    title: '2022年8月',
                    eventContent:
                        '世泊公司荣获2022粤港澳大湾区（广州）智慧交通产业展会 智慧停车优秀企业奖项',
                    imagUrl: require('@/assets/cover/history/2022/zhihui2.jpg')
                },
                {
                    title: '2022年12月',
                    eventContent:
                        '世泊公司参加百度Apollo举行的静态交通协会会议',
                    imagUrl: require('@/assets/cover/history/2022/baidu.jpg')
                }
            ],
            dataEvent23: [
                {
                    title: '2023年5月',
                    eventContent: '世泊公司参加深圳市举办全智展会取得圆满落幕',
                    imagUrl: require('@/assets/cover/history/2023/quanzhizhan.jpg')
                },
                {
                    title: '2023年5月',
                    eventContent:
                        '世泊公司参加《广州市无障碍停车位管理办法》宣贯会',
                    imagUrl: require('@/assets/cover/history/2023/wuzhangai.jpg')
                },
                {
                    title: '2023年6月',
                    eventContent:
                        '世泊公司参加北京2023第二十三届中国国际城市停车产业博览会',
                    imagUrl: require('@/assets/cover/history/2023/beijing.jpg')
                },
                {
                    title: '2023年6月',
                    eventContent:
                        '世泊公司胡金华董事长担任 “第六届寻找中国最美立体车库活动” 专家评委',
                    imagUrl: require('@/assets/cover/history/2023/zuimei.jpg')
                },
                {
                    title: '2023年7月',
                    eventContent: '世泊公司参加2023国际数字能源展',
                    imagUrl: require('@/assets/cover/history/2023/shuzinengyuan.jpg')
                },
                {
                    title: '2023年8月',
                    eventContent:
                        '世泊公司荣获第四届中国城市静态交通协会科技创新奖',
                    imagUrl: require('@/assets/cover/history/2023/jiaotong.jpg')
                },
                {
                    title: '2023年9月',
                    eventContent:
                        '世泊公司与静态交通协会赴广东交通职业技术学院开展交流学习活动',
                    imagUrl: require('@/assets/cover/history/2023/jishuxueyuan.jpg')
                },
                {
                    title: '2023年10月',
                    eventContent: '世泊公司赴广东省机器人协会开展交流学习活动',
                    imagUrl: require('@/assets/cover/history/2023/jiqiren.jpg')
                },
                {
                    title: '2023年11月',
                    eventContent:
                        '世泊公司胡金华董事长参加广东省静态交通协会第二届理事会第四次会议',
                    imagUrl: require('@/assets/cover/history/2023/jingtai.jpg')
                }
            ],
            dataEvent24: [
                {
                    title: '2024年4月',
                    eventContent:
                        '世泊公司受邀参加《广州市停车场条例》修订公众代表座谈会',
                    imagUrl: require('@/assets/cover/history/2024/tingchechangtiaoli.jpg')
                },
                {
                    title: '2024年4月',
                    eventContent:
                        '世泊公司参加第五届粤港澳机器人与人工智能大会暨广东省机器人协会2023年会员大会',
                    imagUrl: require('@/assets/cover/history/2024/dawanqujiqiren.jpg')
                },
                {
                    title: '2024年5月',
                    eventContent:
                        '世泊公司参加“行业标准《停车设备智能搬运器》《机械式停车设备防坠落装置》”专家审定会',
                    imagUrl: require('@/assets/cover/history/2024/shendinghuiyi.jpg')
                },
                {
                    title: '2024年6月',
                    eventContent:
                        '世泊公司参加⼴东省机器⼈协会第⼆季度会员⽇暨⾦融科技交流会',
                    imagUrl: require('@/assets/cover/history/2024/jiqirenhuiyuan.jpg')
                }
            ]
        };
    },
    mounted() {},
    components: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.HomeHistory {
    background-color: #f2f2f2;
    .title {
        font-size: 60px;
        font-size: #0b314f;
        width: 100%;
        font-weight: lighter;
        padding-top: 25px;
        padding-bottom: 50px;
    }
    .main {
        width: 84%;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;
        padding-bottom: 20px;
        overflow: hidden;
        display: flow-root; //新的布局方式——解决float 高度塌陷问题
    }
    .l-box {
        float: left;
    }
    .r-box {
        float: right;
    }
    .content {
        padding: 10px;
    }
    .chat {
        position: relative;
        min-width: 100px;
        max-width: 180px;
        padding: 10px;
        word-wrap: break-word;
        border: 1px solid #e4e7ed;
        border-radius: 4px;
    }
    /deep/ .Companyhistory .el-tabs__header {
        display: flex;
        justify-content: center;
    }
    /deep/ .Companyhistory .el-tabs__header .is-top {
        font-size: 18px;
    }
    /deep/ .Companyhistory .el-carousel {
        width: 1400px;
        margin: 0 auto;
    }
    /deep/ .Companyhistory .el-carousel__container {
        height: 450px;
    }
    .Companyhistory .el-tab-pane {
        margin-top: 14px;
        margin-bottom: 12px;
    }
    .carousel-text {
        height: 80px;
        padding: 0 10px;
    }
    .carousel-text .carousel-title {
        color: #0b314f;
        margin-top: 8px;
        height: 12px;
        font-size: 16px;
        font-weight: bold;
    }
}

@media screen and (max-width: 850px) {
    .title {
        text-align: center;
    }
    .HomeHistory {
        .main {
            margin-top: 15px;
            margin-bottom: 15px;
        }
        .frame-box {
            width: 100%;
            min-height: 600px;
        }
        .l-box {
            float: left;
            margin-bottom: 15px;
        }
        .r-box {
            float: right;
        }
        .content {
            margin-top: 10px;
        }
        /deep/ .Companyhistory .el-tabs__header {
            display: flex;
            justify-content: center;
        }
        /deep/ .Companyhistory .el-carousel {
            width: auto;
            margin: 0 auto;
        }
        /deep/ .Companyhistory .el-carousel__container {
            height: 200px;
        }
    }
}
</style>
