<template>
  <div class="HomeDynamic">
      <div class="CommonWidth">

        <div class="title">新闻动态</div>

        <div class="content">
            <el-row :gutter="20">
                
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item, index) in result.slice(0, 4)" :key="index">
                    <router-link :to="{ path: '/article/' + item.id }" target="_blank" >
                    <div class="item">
                        <div class="cover">
                            <img :src="item.image" width="385px" height="234.25px" />
                        </div>
                        <div class="item-title">
                            {{item.title}}
                        </div>
                        <div class="date"></div><!-- {{item.date}} -->
                        <div class="info">{{item.info}}</div>
                        <div class="more">了解更多 ></div>
                    </div>
                    </router-link>
                </el-col>
                
            </el-row>
        </div>

        <div class="button-li">
            <router-link :to="{ name: 'dynamic' }">
                <div class="button">
                    查看更多 >
                </div>
            </router-link>
        </div>

      </div>
  </div>
</template>

<script>
import articleIndex from '../../data/articleIndex'
export default {
  name: "HomeDynamic",
  props: {
  },
  created() {
      const at = articleIndex['dynamic'].slice()
      this.result = at.reverse("id")
  },
  methods: {
  },
  data() {
    return {
        result:[]
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.HomeDynamic{

    .title{
        font-size: 60px;
        font-size: #0b314f;
        width: 100%;
        font-weight: lighter;
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .item{
        padding-bottom: 90px;
        .cover{
            width: 100%;
            img{width: 100%;}
        }
        .item-title{
            font-size: 24px;
            margin-top: 10px;
            color: #0b314f;
            height: 70px;
            overflow:hidden; 
            text-overflow:ellipsis;
            display:-webkit-box; 
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2; 
        }
        .date{
            font-size: 14px;
            color: #43acff;
            margin-top: 20px;
        }
        .info{
            font-size: 14px;
            color: #0b314f;
            margin-top: 10px;
            overflow:hidden; 
            text-overflow:ellipsis;
            display:-webkit-box; 
            -webkit-box-orient:vertical;
            -webkit-line-clamp:1; 
        }
        .more{
            color: #43acff;
            font-size: 14px;
            margin-top: 20px;
        }
    }

    .button-li{
        width: 100%;
        display: flow-root;
        margin-bottom: 100px;
    }

    .button{
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 8px;
        font-size: 14px;
        color: #ffffff;
        background-color: #008fff;
        float: left;
    }
    .button:hover{
        color: #ffffff;
        background-color: #366586;
    }

}

@media screen and (max-width: 850px) {
    .title{text-align: center;}
    .button{
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 8px;
        font-size: 14px;
        color: #ffffff;
        background-color: #008fff;
        float: none !important;
        width: 100px;
        margin: 0 auto;
        text-align: center !important;
    }
    .button:hover{
        color: #ffffff;
        background-color: #366586;
    }
}
</style>
