<template>
    <div class="HomeNavigation">
        <div class="main">
            <div
                class="frame-box l-box"
                :style="{ backgroundImage: 'url(' + L1Back + ')' }"
            >
                <div class="content">
                    <router-link :to="{ path: '/product/1' }" target="_blank">
                        <div class="title">全自主激光雷达导航 停车机器人</div>
                        <div class="herf">立即了解 ></div>
                    </router-link>
                </div>
            </div>
            <div
                class="frame-box r-box"
                :style="{ backgroundImage: 'url(' + R1Back + ')' }"
            >
                <div class="content">
                    <router-link :to="{ path: '/solution/1' }" target="_blank">
                        <div class="title">世泊智能立体停车方案仿真</div>
                        <div class="herf">立即了解 ></div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import L1Back from '../../assets/nav-agv.jpg';
import R1Back from '../../assets/fangan.jpg';
export default {
    name: 'navigation',
    props: {},
    created() {},
    methods: {},
    data() {
        return {
            L1Back: L1Back,
            R1Back: R1Back
        };
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.HomeNavigation {
    .main {
        margin: 0 auto;
        width: calc(100% - 60px);
        margin-top: 30px;
        margin-bottom: 30px;
        overflow: hidden;
        display: flow-root;
    }

    .frame-box {
        width: calc(50% - 15px);
        overflow: hidden;
        min-height: 850px;
        background-position: bottom;
        background-repeat: no-repeat;
    }
    .l-box {
        float: left;
    }
    .r-box {
        float: right;
    }

    .content {
        width: 50%;
        margin: 0 auto;
        margin-top: 130px;
        min-width: 300px;
        .title {
            font-size: 30px;
            font-weight: lighter;
            margin-bottom: 15px;
            color: #000;
            text-align: center;
        }
        .herf {
            text-align: center;
            font-size: 18px;
            color: #008fff;
        }
    }
}

@media screen and (max-width: 850px) {
    .HomeNavigation {
        .main {
            width: calc(100% - 30px);
            margin-top: 15px;
            margin-bottom: 15px;
        }
        .frame-box {
            width: 100%;
            min-height: 600px;
        }
        .l-box {
            float: left;
            margin-bottom: 15px;
        }
        .r-box {
            float: right;
        }
        .content {
            margin-top: 40px;
        }
    }
}
</style>
