<template>
  <div>
    <firstscreen class="screen" />
    <navigation />
    <company />
    <dynamic />
    <!-- <timeline /> -->
    <history />
    <careers />
  </div>
</template>

<script>
// @ is an alias to /src
import firstscreen from "./Firstscreen.vue";
import navigation from "./Navigation.vue";
import company from "./Company.vue";
import dynamic from "./Dynamic.vue";
import careers from "./Careers.vue";
// import timeline from "./Timeline.vue";
import history from "./History.vue";

export default {
  name: "Home",
  components: {
    firstscreen,
    navigation,
    company,
    dynamic,
    careers,
    // timeline,
    history
  },
};
</script>
<style scope>

/* .screen {
    display: none;
  } */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .screen {
    display: block;
  }
}
</style>
