<template>
  <div class="HomeFirstscreen">

        <video-bg class="background" :sources="['https://public-oss.sbznck.com/shibotong-owev2-website/video-background.mp4']" img="demo/assets/bg.jpg">

            <div class="content">
                <div class="title">停车有我，停车无忧</div>
                <div class="info">了解更快捷的智能停车方式, 采用激光雷达自导大型重载AMR</div>
                <div class="herf">
                    <router-link :to="{ path: '/solution/1' }" target="_blank" >
                    <div class="button Transition-03s">进一步了解></div>
                    </router-link>
                </div>
            </div>

        </video-bg>
      
  </div>
</template>

<script>
export default {
  name: "Firstscreen",
  props: {
  },
  created() {
  },
  methods: {
  },
  data() {
    return {
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.HomeFirstscreen{

    .content{
        width: 70%;
        margin: 0 auto;
        color: #ffffff;
        margin-top: 300px;
        .title{
            font-size: 72px;
            margin-bottom: 20px;
            font-weight: lighter;
        }
        .info{
            font-size: 18px;
            margin-bottom: 20px;
            font-weight: lighter;
        }
        .herf{
            .button{
                padding-top: 12px;
                padding-bottom: 12px;
                padding-left: 25px;
                padding-right: 25px;
                border-radius: 8px;
                font-size: 14px;
                border: 1px solid #ffffff;
                float: left;
                color: #ffffff;
            }
            .button:hover{
                color: #ffffff;
                background-color: #808080;
                border: 1px solid #808080;
            }
        }
    }

    .background{
        max-height: 900px;
        height: calc(100vh - 80px);
        min-height: 600px;
    }
}

@media screen and (max-width: 850px) {
.HomeFirstscreen{

    .content{
        margin-top: 200px;
    }

    .background{
        max-height: 900px;
        height: calc(100vh - 60px);
        min-height: 600px;
    }
}
}
</style>
