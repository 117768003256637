<template>
    <div
        class="HomeCompany"
        :style="{ backgroundImage: 'url(' + L1Back + ')' }"
    >
        <div class="main">
            <div class="CommonWidth">
                <div class="content-l content">
                    <div class="title">广东世泊智能装备科技</div>
                    <div class="text">
                        广东世泊智能装备科技有限公司是一家专业从事智能立体停车库、智能汽车搬运机器人及其相关设备研发、生产、运维的高科技企业，提出基于激光导航技术、多电机联合动作控制、
                        虚拟路径规划的智能机器人立体停车整体方案“世泊智停”。
                        <br />
                        <br />
                        <br />
                        世泊智能汇聚了一批经验丰富的高学历和专业技术人才，专注于智能停车系统研发及整体解决方案，先后获得国家专利10余项
                    </div>
                    <router-link :to="{ name: 'company' }">
                        <div class="button">公司介绍 ></div>
                    </router-link>
                </div>

                <div class="content-r content">
                    <div class="title">专利成果</div>
                    <div>
                        <el-carousel
                            :interval="4000"
                            type="card"
                            class="carousel"
                            height="290px"
                        >
                            <el-carousel-item
                                v-for="(item, index) in img"
                                :key="index"
                            >
                                <div class="imgitem">
                                    <img :src="item.image" />
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import L1Back from '../../assets/company-backgorund.jpg';
export default {
    name: 'HomeCompany',
    props: {},
    created() {},
    methods: {},
    data() {
        return {
            L1Back: L1Back,
            img: [
                {
                    image: require('@/assets/image/offce/1-2019106354835.jpg')
                },
                {
                    image: require('@/assets/image/offce/1-201921826533X.jpg')
                },
                {
                    image: require('@/assets/image/offce/1-2019106354835.jpg')
                },
                {
                    image: require('@/assets/image/offce/1-2019218265486.jpg')
                },
                {
                    image: require('@/assets/image/offce/1-2019218265630.jpg')
                },
                {
                    image: require('@/assets/image/offce/1-2019218269025.jpg')
                },
                {
                    image: require('@/assets/image/offce/1-2019218375594.jpg')
                },
                {
                    image: require('@/assets/image/offce/1-2019305833744.jpg')
                },
                {
                    image: require('@/assets/image/offce/1-2020214398301.jpg')
                },
                {
                    image: require('@/assets/image/offce/1-2021227605041.jpg')
                }
            ]
        };
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.HomeCompany {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;

    .button {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 8px;
        font-size: 14px;
        border: 1px solid #ffffff;
        color: #ffffff;
        float: left;
        margin-top: 60px;
    }
    .button:hover {
        color: #ffffff;
        background-color: #40b0ff;
        border: 1px solid #40b0ff;
    }

    .main {
        min-height: 600px;
        width: 100%;
        display: flow-root;
    }

    .content {
        margin-top: 90px;
    }

    .content-l {
        float: left;
        width: calc(100% - 500px);
        padding-bottom: 100px;
    }

    .content-r {
        float: right;
        width: 400px;
        padding-bottom: 100px;
    }

    .title {
        font-size: 48px;
        color: #ffffff;
        // margin-bottom: 90px;
        font-weight: lighter;
    }

    .text {
        color: #ffffff;
        font-weight: normal;
        margin-top: 80px;
        line-height: 30px;
    }

    .carousel {
        margin-top: 40px;
    }

    .imgitem {
        display: flex;
        justify-content: center;
        img {
            height: 220px;
        }
    }
}

@media screen and (max-width: 850px) {
    .HomeCompany {
        .button {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 25px;
            padding-right: 25px;
            border-radius: 8px;
            font-size: 14px;
            border: 1px solid #ffffff;
            color: #ffffff;
            float: none;
            margin: 0 auto;
            width: 80px;
            text-align: center;
            margin-top: 80px;
        }
        .button:hover {
            color: #ffffff;
            background-color: #40b0ff;
            border: 1px solid #40b0ff;
        }

        .content-l {
            width: 100%;
        }
        .content-r {
            width: 100%;
        }
        .title {
            text-align: center;
        }
    }
}
</style>
