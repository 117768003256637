<template>
  <div class="HomeCareers">

    <div class="CommonWidth">
      <div class="main">

        <div class="l">
            <div class="image">
                <img src="@/assets/careers1.png" />
            </div>
        </div>
        <div class="r">
            <div class="text">想加入我们一起工作吗? 赶紧看看有没有喜欢的职位吧!</div>
            <div class="button-li">
                <router-link :to="{ name: 'careers' }">
                <div class="button">
                    了解正在招聘的职位 >
                </div>
                </router-link>
            </div>
        </div>

      </div>
    </div>
      
  </div>
</template>

<script>
export default {
  name: "HomeCareers",
  props: {
  },
  created() {
  },
  methods: {
  },
  data() {
    return {
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.HomeCareers{
    
    .main{
        margin-top: 200px;
        width: 100%;
        min-height: 200px;
        border: 1px solid #99cbff;
        margin-bottom: 200px;
        display: flow-root;
    }

    .l{
        width: 450px;
        float: left;
    }
    .r{
        margin-bottom: 50px;
        width: calc(100% - 500px);
        float: right;
        .text{
            color: #0b314f;
            font-size: 30px;
            margin-top: 50px;
        }
        .button-li{
            width: 100%;
            display: flow-root;
            margin-top: 20px;
        }

        .button{
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 25px;
            padding-right: 25px;
            border-radius: 8px;
            font-size: 14px;
            color: #ffffff;
            background-color: #008fff;
            float: left;
        }
        .button:hover{
            color: #ffffff;
            background-color: #366586;
        }
    }

    .image{
        display: flex; justify-content: right;
        margin-top: -65px;
    }

}

@media screen and (max-width: 850px) {
.HomeCareers{
    
    .main{
        margin-top: 300px;
        width: 100%;
        min-height: 200px;
        border: 1px solid #99cbff;
        margin-bottom: 80px;
        display: flow-root;
    }

    .l{
        width: 100%;
        float: left;
    }
    .r{
        width: 100%;
        float: right;
        margin-bottom: 10px;
        .text{
            width: calc(100% - 60px);
            padding: 30px;
            color: #0b314f;
            font-size: 30px;
            margin-top: 50px;
        }
    }

    .image{
        display: flex; justify-content: center;
        margin-top: -150px;
    }

        .button-li{
            width: 100%;
            display: flow-root;
            float: none;
            margin-top: 20px;
        }

        .button{
            float: none !important;
            margin: 0 auto;
            width: 150px;
            text-align: center;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 25px;
            padding-right: 25px;
            border-radius: 8px;
            font-size: 14px;
            color: #ffffff;
            background-color: #008fff;
            margin-bottom: 100px;
        }
        .button:hover{
            color: #ffffff;
            background-color: #366586;
        }

}
}
</style>
